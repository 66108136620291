import React from "react";
import { Button, VStack, Text, Heading } from "@chakra-ui/react";

const UserInfo = ({
  title,
  name,
  email,
  category,
  country,
  dateOfBirth,
  editButton,
  changePasswordButton,
  deleteAccountButton,
}) => {
  return (
    <VStack spacing="15px" align="flex-start" textAlign="left">
      <Heading
        as="h1"
        color="textColor"
        textAlign={{ xs: "center", lg: "left" }}
        width="100%"
      >
        {title}
      </Heading>
      <Text color="textColor" fontSize="lg">
        {name}
      </Text>
      <Text color="textColor" fontSize="lg">
        {email}
      </Text>
      <Text color="textColor" fontSize="lg">
        {category}
      </Text>
      <Text color="textColor" fontSize="lg">
        {country}
      </Text>
      <Text color="textColor" fontSize="lg" pb="10px">
        {dateOfBirth}
      </Text>
      <Button variant="standard" fontSize="lg" onClick={editButton.onClick}>
        {editButton.label}
      </Button>
      <Button
        variant="standard"
        fontSize="lg"
        onClick={changePasswordButton.onClick}
      >
        {changePasswordButton.label}
      </Button>
      <Button
        variant="delete"
        fontSize="lg"
        onClick={deleteAccountButton.onClick}
      >
        {deleteAccountButton.label}
      </Button>
    </VStack>
  );
};

export default UserInfo;
