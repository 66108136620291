import React from "react";
import Image from "next/image";
import { Box, Link } from "@chakra-ui/react";

import TwitterIcon from "/public/icons/twitter-icon.svg";
import facebookIcon from "/public/icons/facebook-icon.png";
import instagramIcon from "/public/icons/instagram-icon.svg";
import redXIcon from "/public/icons/red-x-icon.png";

import { FacebookShareButton, TwitterShareButton } from "next-share";

const SocialMedia = ({ type, socialMediaText }) => {
  let socialType = type.toLowerCase();
  let socialIcon;

  const handleInstagram = () => {
    window.open("https://instagram.com", "_blank").focus();
  };

  let facebookText;

  switch (socialType) {
    case "twitter":
      socialIcon = TwitterIcon;
      break;
    case "facebook":
      socialIcon = facebookIcon;
      facebookText = socialMediaText.replace(
        "@EuroAthletics",
        "@EuropeanAthletics"
      );
      break;
    case "instagram":
      socialIcon = instagramIcon;
      break;
    default:
      socialIcon = redXIcon;
      socialType = "Something went wrong";
  }

  const url = "https://irunclean.org";

  return (
    <>
      {socialType === "twitter" && (
        <Box paddingX="10px">
          <TwitterShareButton
            url={url}
            title={socialMediaText}
            hashtags={["irunclean"]}
          >
            <Image src={socialIcon} alt={socialType} width={50} height={50} />
          </TwitterShareButton>
        </Box>
      )}
      {socialType === "facebook" && (
        <Box paddingX="10px">
          <FacebookShareButton
            url={url}
            quote={`${facebookText} "I run clean"`}
            hashtag={"#irunclean"}
          >
            <Image src={socialIcon} alt={socialType} width={50} height={50} />
          </FacebookShareButton>
        </Box>
      )}
      {socialType === "instagram" && (
        <Box paddingX="10px">
          <Link onClick={handleInstagram}>
            <Image src={socialIcon} alt={socialType} width={50} height={50} />
          </Link>
        </Box>
      )}
    </>
  );
};

export default SocialMedia;
