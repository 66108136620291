export const styles = {
  global: (props) => ({
    body: {
      fontFamily: "body",
      //lineHeight: "base",
      bg: "baseLight",
      color: "baseDark",
    },
    a: {
      textDecoration: "none",
      color: "link",
      _hover: {
        textDecoration: "underline",
        color: "linkHover",
      },
    },
    mark: {
      bg: "baseLight",
      color: "baseDark",
      fontWeight: "bold",
      marginRight: "0.2em",
    },
  }),
};
