export const colors = {
  baseLight: "#fff", // brightest color in project
  baseDark: "#000", // darkest color in project
  primary: "#00bbe7", // main color (very blue)
  primaryMedium: "#0099d8", // blue
  primaryDarker: "#002f62", // dark blue
  secondary: "#fcaf17", // orange
  footerText: "#a7a9ac", // grey
  delete: "#f15a22", // red
  imageReplacement: "#d3d3d3", // light grey
  textColor: "#333", // dark grey
  inputBackground: "#eaeaea", // light grey
};
