import React, { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { groq } from "next-sanity";
import client from "lib/client";
import { Box } from "@chakra-ui/react";
import { UserContext } from "src/context/UserContext";
import { Header } from ".";
import { Footer } from ".";
import { ContentContainer } from ".";
import { useAuth } from "src/hooks/api";

const query = groq`
*[_type in ['menu','portalSettings']
 && _lang == $lang]{
  rtl,
  menuItems,
  menuItemsLoggedIn,
  logOutText,
  companyUrl,
  urlText,
  "companyLogo": uploadCompanyLogo.asset->url,
  "companyLogoCaption": uploadCompanyLogo.caption,
  "titleImage": brandTitleImage.asset->url,
  "titleCaption": brandTitleImage.caption,
  "footerLogo": uploadFooterLogo.asset->url,
  "footerCaption": uploadFooterLogo.caption,
  footerText
}
`;

const SiteLayout = ({ children }) => {
  const [data, setData] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { language } = useContext(UserContext);
  const { isAuthenticated, logout } = useAuth();

  useEffect(() => {
    client
      .fetch(query, { lang: language })
      .then((res) => setData(res))
      .catch(console.error);
  }, [language]);

  // checking if the component has mounted to avoid ssr vs csr warning
  useEffect(() => {
    setMounted(true);
  }, []);

  // removing null properties
  data?.forEach((obj) => {
    Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
  });

  const {
    rtl,
    companyUrl,
    urlText,
    menuItems,
    menuItemsLoggedIn,
    logOutText,
    titleImage,
    titleImageCaption,
    companyLogo,
    companyLogoCaption,
    footerLogo,
    footerCaption,
    footerText,
  } = Object.assign({}, ...data);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const router = useRouter();

  return (
    <Box overflow={showMenu && "hidden"} height={showMenu && "100vh"}>
      <header>
        <ContentContainer>
          {typeof window !== "undefined" &&
            mounted &&
            router.pathname !== "/selectlanguage" && (
              <Header
                links={isAuthenticated ? menuItemsLoggedIn : menuItems}
                isAuthenticated={isAuthenticated}
                logOutText={logOutText}
                loggingOut={logout}
                locale={language}
                image={[titleImage, titleImageCaption]}
                logo={[companyLogo, companyLogoCaption]}
                onClick={handleShowMenu}
                isOpen={showMenu}
                rtl={rtl}
              />
            )}
        </ContentContainer>
      </header>
      <main>{children}</main>
      <Footer
        logo={footerLogo}
        caption={footerCaption}
        url={companyUrl}
        urlText={urlText}
        footerText={footerText}
        locale={language}
      />
    </Box>
  );
};

export default SiteLayout;
