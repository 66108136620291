import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const VideoModal = ({ isOpen, onClose, url }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="full"
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalHeader>
          <ModalCloseButton
            variant="modalClose"
            mr={6}
            mt={3}
            p={3}
            bg="textColor"
            color="baseLight"
            size="4xl"
          />
        </ModalHeader>
        <ModalBody w="90vw" h="90vh" margin="0 auto">
          <video width="100%" height="100%" controls>
            <source src={url} type="video/mp4" />
          </video>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoModal;
