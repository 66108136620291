export const CloseButton = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: "semibold",
    //boxShadow: "0 0 2px 2px #efdfde",
  },
  // 3. We can add a new visual variant
  variants: {
    modalClose: {
      bg: "textColor",
      color: "baseLight",
      _hover: {
        bg: "textColor",
      },
    },
  },
};
