import React from "react";
import NextLink from "next/link";
import { Flex, VStack, Link, Box, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";

const DropdownBox = ({
  rtl,
  links,
  locale,
  calculateActiveLinks,
  isAuthenticated,
  loggingOut,
  logOutText,
  toggleMenu,
}) => {
  const MotionFlex = motion(Flex);
  const MotionVStack = motion(VStack);
  const dir = rtl ? "rtl" : "ltr";

  return (
    <MotionFlex
      pos="fixed"
      //minH="calc(100vh - 150px)"
      minW="100vw"
      left={0}
      right={0}
      top="150px"
      zIndex="2"
      margin="auto"
      justify="center"
      align="flex-start"
      p={8}
      bg="baseLight"
      // shadow="inset rgb(0 0 0 / 50%) 0 25px 25px"
      initial={{ opacity: 1, height: 0 }}
      animate={{ opacity: 1, height: "calc(100vh - 150px)" }}
      exit={{ opacity: 1, height: 0 }}
      transition={{ duration: 0.5 }}
    >
      <MotionVStack
        spacing={3}
        width="100%"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {links.map((link) => (
          <Box
            key={`link-${link.itemText}`}
            pos="relative"
            width="100%"
            dir={dir}
          >
            <NextLink href={link.itemLink} locale={locale}>
              <Link
                fontSize="lg"
                width="100%"
                variant={calculateActiveLinks(link)}
                onClick={() => toggleMenu()}
              >
                {link.itemText}
              </Link>
            </NextLink>
            <Box
              display={
                calculateActiveLinks(link) === "navActive" ? "block" : "none"
              }
              pos="absolute"
              right="36px"
              bottom={0}
              w="3000px"
              borderBottom="2px solid"
              borderColor="primary"
            />
          </Box>
        ))}
        {isAuthenticated && (
          <Box width="100%" dir={dir}>
            <Button
              bg="transparent"
              textTransform="uppercase"
              fontSize="lg"
              padding="0"
              mt="-10px"
              onClick={loggingOut}
            >
              {logOutText}
            </Button>
          </Box>
        )}
      </MotionVStack>
    </MotionFlex>
  );
};

export default DropdownBox;
