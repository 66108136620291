export const Select = {
  // 1. We can update the base styles
  baseStyle: {
    field: {
      fontFamily: "Open Sans",
      bg: "inputBackground",
      color: "baseDark",
      fontWeight: "normal",
      borderBottom: "2px solid",
      borderColor: "baseDark",
      rounded: 0,
      borderRadius: 0,
    },
    //boxShadow: "0 0 2px 2px #efdfde",
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    md: {},
    xl: {
      h: "3em",
      fontSize: "lg",
    },
  },
  // 3. We can add a new visual variant
  variants: {
    rtl: {
      icon: {
        left: 0,
        right: "initial",
      },
    },
  },
};
