export const Text = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: "normal",
    fontFamily: "Open Sans",
    //boxShadow: "0 0 2px 2px #efdfde",
  },
  // 3. We can add a new visual variant
  variants: {
    highlight: {
      bg: "secondary",
      color: "baseLight",
      paddingY: "8px",
      paddingX: "60px",
      border: 1,
      rounded: "2px",
      roundedTopLeft: "42px",
      roundedBottomRight: "42px",
      /*textTransform: "uppercase",*/
      fontWeight: "normal",
    },
  },
};
