import "../../../aws-amplify.config";

import React, { useState, useContext } from "react";
import { useRouter } from "next/router";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Stack,
  Checkbox,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { useAuth, useUsers } from "src/hooks/api";
import { UserContext } from "src/context/UserContext";

const DeleteAccountForm = ({
  goBack,
  text1,
  text2,
  text3,
  checkboxLabel,
  backButtonLabel,
  deleteButtonLabel,
  dir,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deleteAccount: false,
    },
  });

  const router = useRouter();
  const { language } = useContext(UserContext);
  const { logout, userId } = useAuth();
  const { deleteUser } = useUsers();
  const [errorMsg, setErrorMsg] = useState();

  const onSubmit = (data) => {
    console.log(data);
  };

  // Delete a user from cognito and then clear sessionstorage and reroute
  const awsDeleteUser = async () => {
    const response = await deleteUser({ userId });
    console.log(response);

    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((user) => {
        user.deleteUser(async (error) => {
          if (error) {
            setErrorMsg({
              title: "Error",
              message: "Something went wrong",
            });
            return;
          }

          // Log the user out and redirect to front page
          Auth.signOut({ global: true });
          logout();
          router.push("/", "/", { locale: language });
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {errorMsg && (
        <Box
          width="100%"
          maxWidth="400px"
          margin="0 auto"
          color={"white"}
          dir={dir}
        >
          <Alert status="error" bg="red" mt={4}>
            <AlertIcon color={"white"} />
            <Box flex="1">
              <AlertTitle mr={2}>{errorMsg?.title}</AlertTitle>
              <AlertDescription display="block">
                {errorMsg?.message}
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box width="100%" maxWidth="400px" margin="0 auto" dir={dir}>
          <VStack mb={12} spacing="0px" align="flex-start">
            <Text fontSize="2xl" color="baseDark">
              {text1}
            </Text>
            <Text fontSize="2xl" color="baseDark">
              {text2}
            </Text>
            <Text fontSize="2xl" color="baseDark">
              {text3}
            </Text>
          </VStack>

          <Controller
            control={control}
            name="deleteAccount"
            rules={{ required: false }}
            render={({ field }) => (
              <Checkbox variant="none" size="md" {...field}>
                <Text fontSize="lg">{checkboxLabel || ""}</Text>
              </Checkbox>
            )}
          />
        </Box>
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          py={12}
          spacing="30px"
          maxWidth="400px"
          width="100%"
          margin="0 auto"
        >
          <Button variant="standardOutline" onClick={goBack}>
            {backButtonLabel || "Cancel"}
          </Button>
          <Button
            variant={"delete"}
            type="submit"
            disabled={!watch("deleteAccount")}
            onClick={() => awsDeleteUser()}
          >
            {deleteButtonLabel || "Delete"}
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default DeleteAccountForm;
