import React, { useRef, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  Button,
  VStack,
  Stack,
  Input,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";

const SignupForm = ({
  onSubmit,
  errors,
  control,
  handleSubmit,
  watch,
  months,
  groups,
  firstnameLabel,
  lastnameLabel,
  emailLabel,
  categoryLabel,
  disciplineLabel,
  countryLabel,
  dropdownMessages,
  dateOfBirthText,
  passwordText,
  confirmPasswordText,
  buttonLabel,
  rtl,
}) => {
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);

  const password = useRef({});
  password.current = watch("password", "");

  const emailRegEx = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

  const days = 31;
  const startYear = new Date().getFullYear() - 100;
  const endYear = new Date().getFullYear() - 13;

  const ReturnDays = () => {
    const daysArr = [];
    if (!days) {
      return;
    }
    for (let day = 1; day <= days; day++) {
      daysArr.push(
        <option key={`days-${day}`} value={day}>
          {day}
        </option>
      );
    }
    return daysArr;
  };

  const ReturnYears = () => {
    const yearsArr = [];
    for (let year = endYear; year >= startYear; year--) {
      yearsArr.push(
        <option key={`years-${year}`} value={year}>
          {year}
        </option>
      );
    }
    return yearsArr;
  };

  const dir = rtl ? "rtl" : "ltr";

  useEffect(() => {
    if (!groups) {
      return;
    }
    const sortedCategories = groups?.role?.sort((a, b) =>
      a.title > b.title ? 1 : b.title > a.title ? -1 : 0
    );
    setCategories(sortedCategories);

    let sortedCountries = groups?.groups;
    if (sortedCountries) {
      sortedCountries.sort((a, b) =>
        a.title > b.title ? 1 : a.title < b.title ? -1 : 0
      );
      // moves "Other" to the end of the list
      let index = sortedCountries.findIndex(
        (country) => country.title === "Other"
      );
      let other = sortedCountries.splice(index, 1);
      sortedCountries.push(...other);
      setCountries(sortedCountries);
    }
  }, [groups]);

  // get days in month, return true if valid
  const validDateCheck = (year) => {
    let day = watch("dateOfBirthDay", "");
    let month = watch("dateOfBirthMonth", "");
    if (day && month && year) {
      let daysPrMonth = new Date(year, month, 0).getDate();
      if (day <= daysPrMonth) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack
        spacing="20px"
        width="100%"
        maxWidth="400px"
        margin="0 auto"
        dir={dir}
      >
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {firstnameLabel.placeholder}
          </FormLabel>
          <Controller
            control={control}
            name="firstname"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                variant="none"
                borderRadius={0}
                size="lg"
                autoComplete="given-name"
                placeholder={firstnameLabel.placeholder}
              />
            )}
          />
          {errors.firstname && errors.firstname.type === "required" && (
            <Text role="alert" fontSize="sm" color="red">
              {firstnameLabel.errorMessage}
            </Text>
          )}
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {lastnameLabel.placeholder}
          </FormLabel>
          <Controller
            control={control}
            name="lastname"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                variant="none"
                borderRadius={0}
                size="lg"
                autoComplete="family-name"
                placeholder={lastnameLabel.placeholder}
              />
            )}
          />
          {errors.lastname && errors.lastname.type === "required" && (
            <Text role="alert" fontSize="sm" color="red">
              {lastnameLabel.errorMessage}
            </Text>
          )}
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {emailLabel?.placeholder}
          </FormLabel>
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              validate: (value) =>
                emailRegEx.test(value) || emailLabel?.errorMessageInvalidEmail,
            }}
            render={({ field }) => (
              <Input
                {...field}
                variant="none"
                borderRadius={0}
                size="lg"
                type="email"
                autoComplete="email"
                placeholder={emailLabel?.placeholder}
              />
            )}
          />
          {errors.email && errors.email.type === "required" && (
            <Text role="alert" fontSize="sm" color="red">
              {emailLabel?.errorMessage}
            </Text>
          )}
          {errors.email && errors.email.type === "validate" && (
            <Text role="alert" fontSize="sm" color="red">
              {errors.email.message}
            </Text>
          )}
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {categoryLabel}
          </FormLabel>
          <Controller
            control={control}
            name="category"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                size="lg"
                variant={rtl ? "rtl" : "none"}
                borderRadius={0}
                {...field}
                placeholder={categoryLabel}
              >
                {categories?.length > 0 &&
                  categories.map((group) => {
                    if (group?.meta?.role === "user-role") {
                      return (
                        <option key={`group-${group.id}`} value={group.id}>
                          {group?.title}
                        </option>
                      );
                    }
                  })}
              </Select>
            )}
          />
          {errors.category && errors.category.type === "required" && (
            <Text role="alert" fontSize="sm" color="red">
              {dropdownMessages?.dropdownCategory}
            </Text>
          )}
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {disciplineLabel}
          </FormLabel>
          <Controller
            control={control}
            name="discipline"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                size="lg"
                variant={rtl ? "rtl" : "none"}
                borderRadius={0}
                {...field}
                placeholder={disciplineLabel}
              >
                {groups?.disciplines?.length > 0 &&
                  groups?.disciplines.map((group) => {
                    if (group?.meta?.role === "discipline") {
                      return (
                        <option key={`group-${group.id}`} value={group.id}>
                          {group.title}
                        </option>
                      );
                    }
                  })}
              </Select>
            )}
          />
          {errors.discipline && errors.discipline.type === "required" && (
            <Text role="alert" fontSize="sm" color="red">
              {dropdownMessages?.dropdownDisipline}
            </Text>
          )}
        </Box>
        {countries.length > 0 && (
          <Box width="100%">
            <FormLabel display="block" mb="3px">
              {countryLabel}
            </FormLabel>
            <Controller
              control={control}
              name="country"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size="lg"
                  variant={rtl ? "rtl" : "none"}
                  borderRadius={0}
                  {...field}
                  placeholder={countryLabel}
                >
                  {countries.length > 0 &&
                    countries.map((country) => {
                      return (
                        <option key={`${country.id}`} value={country.id}>
                          {country.title}
                        </option>
                      );
                    })}
                </Select>
              )}
            />
            {errors.country && errors.country.type === "required" && (
              <Text role="alert" fontSize="sm" color="red">
                {dropdownMessages?.dropdownCountry}
              </Text>
            )}
          </Box>
        )}
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {dateOfBirthText?.labelDOB}
          </FormLabel>
          {((errors.dateOfBirthDay &&
            errors.dateOfBirthDay.type === "required") ||
            (errors.dateOfBirthMonth &&
              errors.dateOfBirthMonth.type === "required") ||
            (errors.dateOfBirthYear &&
              errors.dateOfBirthYear.type === "required")) && (
            <Text role="alert" fontSize="sm" color="red">
              {dateOfBirthText?.errorMessageDOB}
            </Text>
          )}
          <VStack spacing="0px">
            <Controller
              control={control}
              name="dateOfBirthDay"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size="lg"
                  variant={rtl ? "rtl" : "none"}
                  borderRadius={0}
                  {...field}
                  placeholder={dateOfBirthText?.labelDay}
                >
                  <ReturnDays />
                </Select>
              )}
            />
            <Controller
              control={control}
              name="dateOfBirthMonth"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size="lg"
                  variant={rtl ? "rtl" : "none"}
                  borderRadius={0}
                  {...field}
                  placeholder={dateOfBirthText?.labelMonth}
                >
                  {months?.length > 0 &&
                    months.map((month, index) => {
                      return (
                        <option key={month} value={`${index + 1}`}>
                          {month}
                        </option>
                      );
                    })}
                </Select>
              )}
            />
            <Controller
              control={control}
              name="dateOfBirthYear"
              rules={{
                required: true,
                validate: (year) => validDateCheck(year),
              }}
              render={({ field }) => (
                <Select
                  size="lg"
                  variant={rtl ? "rtl" : "none"}
                  borderRadius={0}
                  {...field}
                  placeholder={dateOfBirthText?.labelYear}
                >
                  <ReturnYears />
                </Select>
              )}
            />
            {errors.dateOfBirthYear &&
              errors.dateOfBirthYear.type === "validate" && (
                <Text role="alert" fontSize="sm" color="red">
                  {dateOfBirthText?.errorMessageDOB}
                </Text>
              )}
          </VStack>
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {passwordText?.label}
          </FormLabel>
          <Controller
            control={control}
            name="password"
            rules={{ required: true, minLength: 8 }}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                autoComplete="new-password"
                variant="none"
                borderRadius={0}
                size="lg"
                placeholder={passwordText?.placeholder}
              />
            )}
          />
          {errors.password && errors.password.type === "required" && (
            <Text role="alert" fontSize="sm" color="red">
              {passwordText.errorMessageRequired}
            </Text>
          )}
          {errors.password && errors.password.type === "minLength" && (
            <Text role="alert" fontSize="sm" color="red">
              {confirmPasswordText.errorMessageRequirements}
            </Text>
          )}
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {confirmPasswordText?.label}
          </FormLabel>
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: true,
              validate: (value) =>
                value === password.current ||
                confirmPasswordText.errorMessageNotMatched,
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                variant="none"
                borderRadius={0}
                size="lg"
                autoComplete="new-password"
                placeholder={confirmPasswordText?.placeholder}
              />
            )}
          />
          {errors.confirmPassword &&
            errors.confirmPassword.type === "required" && (
              <Text role="alert" fontSize="sm" color="red">
                {confirmPasswordText.errorMessageRequired}
              </Text>
            )}
          {errors.confirmPassword &&
            errors.confirmPassword.type === "validate" && (
              <Text role="alert" fontSize="sm" color="red">
                {errors.confirmPassword.message}
              </Text>
            )}
        </Box>
      </VStack>
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        py={12}
        maxWidth="400px"
        margin="0 auto"
      >
        <Button variant="standard" type="submit">
          {buttonLabel}
        </Button>
      </Stack>
    </form>
  );
};

export default SignupForm;
