import React, { useContext } from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { AnimatePresence } from "framer-motion";
import { UserContext } from "src/context/UserContext";
import {
  Box,
  Button,
  HStack,
  Flex,
  VStack,
  Image,
  Link,
  Center,
  IconButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import DropdownBox from "./DropdownBox";

const Header = ({
  rtl,
  links,
  locale,
  image,
  logo,
  logOutText,
  isAuthenticated,
  onClick,
  isOpen,
  loggingOut,
}) => {
  const router = useRouter();
  const { language } = useContext(UserContext);
  const [isSmallerThan900] = useMediaQuery("(max-width: 900px)");
  const dir = rtl ? "rtl" : "ltr";

  const calculateActiveLinks = (link) => {
    if (
      router.pathname === link.itemLink ||
      "/" + router.pathname.split("/")[1] === link?.subPath
    ) {
      return "navActive";
    } else if (
      router.pathname === "/auth/signup" &&
      link.itemLink === "/auth/login"
    ) {
      return "navActive";
    } else {
      return "nav";
    }
  };

  const logOut = () => {
    router.push(`/`, `/`, { locale: language });
    loggingOut();
  };

  if (isSmallerThan900 && typeof window.matchMedia !== "undefined") {
    return (
      <>
        <Box h="150px" width="100%" pos="relative">
          <IconButton
            variant="menu"
            pos="absolute"
            top="50%"
            transform="translateY(-50%)"
            color="primary"
            onClick={onClick}
            icon={
              !isOpen ? <GiHamburgerMenu size={32} /> : <IoClose size={32} />
            }
          />
          <Center h="100%">
            <Image
              margin="0 auto"
              align="center"
              height="76px"
              src={logo[0]}
              alt={logo[1]}
            />
          </Center>
        </Box>
        <AnimatePresence>
          {isOpen && (
            <DropdownBox
              rtl={rtl}
              links={links}
              calculateActiveLinks={calculateActiveLinks}
              locale={locale}
              router={router}
              isAuthenticated={isAuthenticated}
              loggingOut={logOut}
              logOutText={logOutText}
              toggleMenu={onClick}
            />
          )}
        </AnimatePresence>
      </>
    );
  }

  return (
    <Flex
      justify="space-between"
      width="100%"
      h="150px"
      align="center"
      pos="relative"
    >
      <VStack direction="column" spacing="10px" align="flex-start">
        <Image height="60px" src={image[0]} alt={image[1]} />
        <HStack spacing="24px">
          {links?.map((link) => (
            <Box
              key={`link-${link.itemText}`}
              pos="relative"
              dir={dir}
              margin={dir ? "0 24px 0 0" : "0 0 0 25px"}
            >
              <NextLink href={link.itemLink} locale={locale}>
                <Link
                  fontSize="15px"
                  whiteSpace="nowrap"
                  variant={calculateActiveLinks(link)}
                >
                  {link.itemText}
                </Link>
              </NextLink>
              <Box
                display={
                  calculateActiveLinks(link) === "navActive" ? "block" : "none"
                }
                pos="absolute"
                right="36px"
                bottom={0}
                w="3000px"
                borderBottom="2px solid"
                borderColor="primary"
              />
            </Box>
          ))}
          {isAuthenticated && (
            <Button
              dir={dir}
              bg="transparent"
              textTransform="uppercase"
              fontSize="md"
              transform="translate(-20px)"
              _hover={{ bg: "transparent", textDecoration: "underline" }}
              onClick={logOut}
            >
              {logOutText}
            </Button>
          )}
        </HStack>
      </VStack>
      <Image
        height="55px"
        position="absolute"
        top="20px"
        right="0"
        src={logo[0]}
        alt={logo[1]}
      />
    </Flex>
  );
};

export default Header;
