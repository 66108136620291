import Amplify from "aws-amplify";

//import awsconfig from "./src/aws-exports";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.NEXT_PUBLIC_AWS_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.NEXT_PUBLIC_AWS_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.NEXT_PUBLIC_AWS_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_USER_POOL_APP_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
  },
});
