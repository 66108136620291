export const Link = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: "semibold",
    fontFamily: "Open Sans",
    //boxShadow: "0 0 2px 2px #efdfde",
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    xl: {
      h: "3em",
      fontSize: "lg",
    },
  },
  // 3. We can add a new visual variant
  variants: {
    nav: {
      color: "baseDark",
      textTransform: "uppercase",
      _hover: {
        cursor: "pointer",
      },
    },
    navActive: {
      display: "block",
      bg: "primary",
      color: "baseLight",
      paddingY: "5px",
      paddingX: "15px",
      border: 1,
      rounded: "2px",
      roundedTopLeft: "22px",
      roundedBottomRight: "22px",
      textTransform: "uppercase",
      _hover: {
        cursor: "default",
        bg: "primary",
        color: "baseLight",
        textDecoration: "none",
      },
    },
    signup: {
      display: "block",
      bg: "baseLight",
      color: "primaryDarker",
      paddingY: "7px",
      paddingX: "65px",
      border: 1,
      rounded: "0px",
      roundedTopLeft: "42px",
      roundedBottomRight: "42px",
      /*textTransform: "uppercase",*/
      zIndex: 2,
      _hover: {
        cursor: "pointer",
      },
    },
    login: {
      display: "block",
      bg: "primaryMedium",
      color: "baseLight",
      paddingY: "7px",
      paddingX: "75px",
      border: 1,
      rounded: "0px",
      roundedBottomRight: "42px",
      /*textTransform: "uppercase",*/
      marginLeft: "-39px",
      _hover: {
        cursor: "pointer",
      },
    },
    login2: {
      display: "block",
      bg: "baseLight",
      color: "primaryDarker",
      paddingY: "7px",
      paddingX: "75px",
      border: 1,
      rounded: "0px",
      roundedBottomRight: "42px",
      marginLeft: "-39px",
      _hover: {
        cursor: "pointer",
      },
    },
    login3: {
      display: "block",
      bg: "baseLight",
      color: "primaryDarker",
      paddingY: "7px",
      paddingX: "75px",
      border: 1,
      rounded: "0px",
      roundedTopLeft: "42px",
      marginLeft: "-39px",
      _hover: {
        cursor: "pointer",
      },
    },
    courseStart: {
      display: "block",
      bg: "primaryDarker",
      color: "baseLight",
      paddingY: "5px",
      paddingX: "34px",
      border: 1,
      rounded: "2px",
      roundedTopLeft: "34px",
      roundedBottomRight: "34px",
      textTransform: "uppercase",
      _hover: {
        cursor: "pointer",
        bg: "primaryDarker",
        color: "baseLight",
        textDecoration: "none",
      },
    },
    courseStart2: {
      display: "block",
      bg: "primaryDarker",
      color: "baseLight",
      paddingY: "5px",
      paddingX: "34px",
      border: 1,
      rounded: "2px",
      roundedTopRight: "34px",
      roundedBottomLeft: "34px",
      textTransform: "uppercase",
      _hover: {
        cursor: "pointer",
        bg: "primaryDarker",
        color: "baseLight",
        textDecoration: "none",
      },
    },
  },
};
