import "../../../aws-amplify.config";

import React, { useContext, useEffect, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "next/router";
import { UserContext } from "src/context/UserContext";
import { Auth } from "aws-amplify";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  VStack,
  Stack,
  Input,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { useAuth, useUsers, useUser, useGroups } from "src/hooks/api";

const EditUserForm = ({
  months,
  goBack,
  firstnameLabel,
  lastnameLabel,
  emailLabel,
  errorFirstName,
  errorLastName,
  errorEmail,
  errorEmailTaken,
  errorEmailInvalid,
  errorCategory,
  errorDiscipline,
  errorCountry,
  errorDMY,
  categoryLabel,
  disciplineLabel,
  countryLabel,
  dateOfBirthText,
  backButtonLabel,
  saveButtonLabel,
  changeImageButtonLabel,
  editUserResponses,
  infoVerificationCode,
  textfieldVerifyCode,
  globalErrorMessage,
  footerText,
  rtl,
  dmy,
}) => {
  const { storeLanguage, language, listOfLanguages } = useContext(UserContext);
  const router = useRouter();
  const { user, getUserGroups, addUserToGroups, removeUserFromGroups } =
    useUser();
  const { update } = useUsers();
  const { userId, isAuthenticated } = useAuth();
  const { getGroupsWithClientId, groups } = useGroups();

  const [userGroups, setUserGroups] = useState(null);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [userCountry, setUserCountry] = useState([]);
  const [userCategory, setUserCategory] = useState();
  const [userDiscipline, setUserDiscipline] = useState();
  const [userDay, setUserDay] = useState();
  const [userMonth, setUserMonth] = useState();
  const [userYear, setUserYear] = useState();

  const [verifyView, setVerifyView] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState();

  const emailRegEx = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      (
        user,
        userCountry,
        userDiscipline,
        userCategory,
        userDay,
        userMonth,
        userYear
      ) => {
        return {
          firstname: user?.firstName || "",
          lastname: user?.lastName || "",
          email: user?.email || "",
          category: userCategory ? userCategory : "",
          discipline: userDiscipline ? userDiscipline : "",
          country: userCountry ? userCountry : "",
          dateOfBirthDay: userDay ? userDay : "",
          dateOfBirthMonth: userMonth ? userMonth : "",
          dateOfBirthYear: userYear ? userYear : "",
          language: user?.language,
        };
      },
      []
    ),
  });

  useEffect(() => {
    if (!userId || !isAuthenticated) {
      return;
    }
    getGroupsWithClientId();
    const f = async () => {
      const res = await getUserGroups(userId);
      setUserGroups(res);
    };
    f();
  }, [userId, isAuthenticated]);

  useEffect(() => {
    if (!user || !groups || !userGroups) {
      return;
    }
    const filterMonth = months?.filter(
      (month, index) =>
        index + 1 === Number(user.meta.dateOfBirth.split(".")[1])
    );
    const tempCategory = userGroups?.included?.filter(
      (cat) => cat.attributes.meta?.role === "user-role"
    );
    const tempDiscipline = userGroups?.included?.filter(
      (cat) => cat.attributes.meta?.role === "discipline"
    );
    const tempCountry = userGroups?.included?.filter(
      (country) => country.attributes.meta?.role === "federation"
    );
    const sortedCategories = groups?.role?.sort((a, b) =>
      a.title > b.title ? 1 : b.title > a.title ? -1 : 0
    );

    let sortedCountries = groups?.groups;
    if (sortedCountries) {
      sortedCountries.sort((a, b) =>
        a.title > b.title ? 1 : a.title < b.title ? -1 : 0
      );
      // moves "Other" to the end of the list
      let index = sortedCountries.findIndex(
        (country) => country.title === "Other"
      );
      let other = sortedCountries.splice(index, 1);
      sortedCountries.push(...other);
      setCountries(sortedCountries);
    }

    setUserDay(user.meta.dateOfBirth.split("-")[2]);
    setUserMonth(user.meta.dateOfBirth.split("-")[1]);
    setUserYear(user.meta.dateOfBirth.split("-")[0]);
    setUserCategory(tempCategory[0]?.id);
    setUserDiscipline(tempDiscipline[0]?.id);
    setUserCountry(tempCountry[0]?.id);
    setDisciplines(groups?.disciplines);
    setCategories(sortedCategories);
  }, [
    user,
    userGroups,
    groups,
    userCategory,
    userDiscipline,
    userCountry,
    userDay,
    userMonth,
    userYear,
    months,
  ]);

  useEffect(() => {
    if (!user || !userGroups) {
      return;
    }

    reset({
      firstname: user?.firstName || "",
      lastname: user?.lastName || "",
      email: user?.email || "",
      category: userCategory ? userCategory : "",
      discipline: userDiscipline ? userDiscipline : "",
      country: userCountry ? userCountry : "",
      dateOfBirthDay: userDay ? userDay : "",
      dateOfBirthMonth: userMonth ? userMonth : "",
      dateOfBirthYear: userYear ? userYear : "",
      language: user?.language,
    });
  }, [
    user,
    userGroups,
    userDay,
    userMonth,
    userYear,
    userCountry,
    userCategory,
    userDiscipline,
    reset,
  ]);

  useEffect(() => {
    // when the data is saved, the alert is displayed for 3 seconds
    setTimeout(() => {
      setSuccessMsg();
    }, 3000);
  }, [successMsg]);

  const days = 31;
  const startYear = new Date().getFullYear() - 100;
  const endYear = new Date().getFullYear() - 13;

  const ReturnDays = () => {
    const daysArr = [];
    if (!days) {
      return;
    }
    for (let day = 1; day <= days; day++) {
      let dayDobbleDigit = day.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
      daysArr.push(
        <option key={`days-${day}`} value={dayDobbleDigit}>
          {day}
        </option>
      );
    }
    return daysArr;
  };

  const monthsArr = [];
  if (months?.length > 0) {
    months.map((month, index) => {
      monthsArr.push({ numeric: index + 1, long: month });
    });
  }

  const ReturnYears = () => {
    const yearsArr = [];
    for (let year = endYear; year >= startYear; year--) {
      yearsArr.push(
        <option key={`years-${year}`} value={year}>
          {year}
        </option>
      );
    }
    return yearsArr;
  };

  const navigate = (path) => {
    if (path) {
      router.push(`${path}`, `${path}`, { locale: language });
    }
  };

  const updateUserProfile = async (data) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (data && user && groups) {
      // email not changed
      if (data.email.toString().trim() === user?.email) {
        // update fields to task-lms-api

        const dayFormatted = parseInt(
          getValues("dateOfBirthDay")
        ).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        });
        const monthFormatted = parseInt(
          getValues("dateOfBirthMonth")
        ).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        });

        const birthDate = `${getValues(
          "dateOfBirthYear"
        )}-${monthFormatted}-${dayFormatted}`;

        const res = await update({
          userId: userId,
          countryId: getValues("country"),
          email: data.email.toString().trim(),
          firstName: data.firstname.toString(),
          lastName: data.lastname.toString(),
          language: getValues("language"),
          profileCompleted: true,
          meta: {
            dateOfBirth: birthDate,
          },
        });

        // remove user from groups
        let tempCat = [];
        userGroups?.included?.map((group) => {
          if (
            group.attributes.meta?.role === "user-role" ||
            group.attributes.meta?.role === "discipline" ||
            group.attributes.meta?.role === "federation"
          ) {
            tempCat.push(group);
          }
        });
        await removeUserFromGroups(tempCat, userId);
        // add user to groups
        let tempCat2 = [];
        tempCat2.push(getValues("category"));
        tempCat2.push(getValues("discipline"));
        tempCat2.push(getValues("country"));
        await addUserToGroups(tempCat2, userId);

        storeLanguage(getValues("language"));
        window.scrollTo({ top: 0, behavior: "smooth" });

        setSuccessMsg(editUserResponses.success);
        console.log(res);

        setTimeout(() => {
          navigate("/account/userProfile");
        }, 3000);
      } else {
        await Auth.updateUserAttributes(currentUser, {
          email: data.email.toString().trim(),
          given_name: data.firstname,
          family_name: data.lastname,
        })
          .then(() => {
            setErrorMsg(null);
            setVerifyView(true);
          })
          .catch((error) => {
            Sentry.captureMessage(
              `name:${error.name} | code:${error.code} | message:${error.message}`
            );
            console.log(error);
            const errorEmailText = errorEmailTaken.replace(
              "{{email}}",
              getValues("email")
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (error.code === "AliasExistsException") {
              setErrorMsg(errorEmailText);
            } else {
              setErrorMsg(globalErrorMessage);
            }
          });
      }
    }
  };

  const verifyUserProfile = async (data) => {
    await Auth.verifyCurrentUserAttributeSubmit(
      "email",
      data.confirmationCodeField.toString().trim()
    )
      .then(async () => {
        const dayFormatted = parseInt(
          getValues("dateOfBirthDay")
        ).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        });
        const monthFormatted = parseInt(
          getValues("dateOfBirthMonth")
        ).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        });

        const birthDate = `${getValues(
          "dateOfBirthYear"
        )}-${monthFormatted}-${dayFormatted}`;

        const res = await update({
          userId: userId,
          countryId: getValues("country"),
          email: data.email.toString().trim(),
          firstName: data.firstname.toString(),
          lastName: data.lastname.toString(),
          language: getValues("language"),
          profileCompleted: true,
          meta: {
            dateOfBirth: birthDate,
          },
        });

        // remove user from groups
        let tempCat = [];
        userGroups?.included?.map((group) => {
          if (
            group.attributes.meta?.role === "user-role" ||
            group.attributes.meta?.role === "discipline" ||
            group.attributes.meta?.role === "federation"
          ) {
            tempCat.push(group);
          }
        });
        await removeUserFromGroups(tempCat, userId);
        // add user to groups
        let tempCat2 = [];
        tempCat2.push(getValues("category"));
        tempCat2.push(getValues("discipline"));
        tempCat2.push(getValues("country"));
        await addUserToGroups(tempCat2, userId);

        storeLanguage(getValues("language"));
        window.scrollTo({ top: 0, behavior: "smooth" });

        setSuccessMsg(editUserResponses.success);
        console.log(res);
        setTimeout(() => {
          navigate("/account/userProfile");
        }, 3000);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
        setErrorMsg(textfieldVerifyCode.errorMessage);
      });
  };

  const onSubmit = (data) => {
    updateUserProfile(data);
  };

  const onSubmitVerify = (data) => {
    verifyUserProfile(data);
  };

  const dir = rtl ? "rtl" : "ltr";

  // get days in month, return true if valid
  const validDateCheck = (year) => {
    let day = watch("dateOfBirthDay", "");
    let month = watch("dateOfBirthMonth", "");
    if (day && month && year) {
      let daysPrMonth = new Date(year, month, 0).getDate();
      if (day <= daysPrMonth) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <>
      <Box maxW="400px" width="100%" margin="10px auto">
        {successMsg && (
          <Alert status="success" mt="15px">
            <AlertIcon />
            <Box flex="1">{successMsg}</Box>
          </Alert>
        )}
        {errorMsg && (
          <Box pt="15px">
            <Text role="alert" fontSize="sm" color="red">
              {errorMsg}
            </Text>
          </Box>
        )}
      </Box>
      {!verifyView ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack
            spacing="20px"
            width="100%"
            maxWidth="400px"
            margin="0 auto"
            dir={dir}
          >
            <Box width="100%">
              {firstnameLabel && (
                <FormLabel display="block" mb="3px">
                  {firstnameLabel}
                </FormLabel>
              )}

              <Controller
                control={control}
                name="firstname"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    variant="none"
                    borderRadius={0}
                    size="lg"
                    placeholder={firstnameLabel || ""}
                  />
                )}
              />
              {errors.firstname && errors.firstname.type === "required" && (
                <Text role="alert" fontSize="sm" color="red">
                  {errorFirstName}
                </Text>
              )}
            </Box>
            <Box width="100%">
              {lastnameLabel && (
                <FormLabel display="block" mb="3px">
                  {lastnameLabel}
                </FormLabel>
              )}

              <Controller
                control={control}
                name="lastname"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    variant="none"
                    borderRadius={0}
                    size="lg"
                    placeholder={lastnameLabel || ""}
                  />
                )}
              />
              {errors.lastname && errors.lastname.type === "required" && (
                <Text role="alert" fontSize="sm" color="red">
                  {errorLastName}
                </Text>
              )}
            </Box>
            <Box width="100%">
              {emailLabel && (
                <FormLabel display="block" mb="3px">
                  {emailLabel}
                </FormLabel>
              )}
              <Controller
                control={control}
                name="email"
                rules={{
                  required: true,
                  validate: (value) =>
                    emailRegEx.test(value) || errorEmailInvalid,
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    variant="none"
                    borderRadius={0}
                    size="lg"
                    placeholder={emailLabel || ""}
                  />
                )}
              />
              {errors.email && errors.email.type === "required" && (
                <Text role="alert" fontSize="sm" color="red">
                  {errorEmail}
                </Text>
              )}
              {errors.email && errors.email.type === "validate" && (
                <Text role="alert" fontSize="sm" color="red">
                  {errors.email.message}
                </Text>
              )}
            </Box>
            <Box width="100%">
              {categoryLabel && (
                <FormLabel display="block" mb="3px">
                  {categoryLabel}
                </FormLabel>
              )}

              <Controller
                control={control}
                name="category"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="lg"
                    variant={rtl ? "rtl" : "none"}
                    borderRadius={0}
                    {...field}
                    placeholder={categoryLabel || ""}
                  >
                    {categories?.length > 0 &&
                      categories.map((category, index) => {
                        return (
                          <option
                            key={`${category}-${index}`}
                            value={category.id}
                          >
                            {category.title}
                          </option>
                        );
                      })}
                  </Select>
                )}
              />
              {errors.category && errors.category.type === "required" && (
                <Text role="alert" fontSize="sm" color="red">
                  {errorCategory}
                </Text>
              )}
            </Box>
            <Box width="100%">
              {disciplineLabel && (
                <FormLabel display="block" mb="3px">
                  {disciplineLabel}
                </FormLabel>
              )}

              <Controller
                control={control}
                name="discipline"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="lg"
                    variant={rtl ? "rtl" : "none"}
                    borderRadius={0}
                    {...field}
                    placeholder={disciplineLabel || ""}
                  >
                    {disciplines?.length > 0 &&
                      disciplines.map((discipline, index) => {
                        return (
                          <option
                            key={`${discipline}-${index}`}
                            value={discipline.id}
                          >
                            {discipline.title}
                          </option>
                        );
                      })}
                  </Select>
                )}
              />
              {errors.discipline && errors.discipline.type === "required" && (
                <Text role="alert" fontSize="sm" color="red">
                  {errorDiscipline}
                </Text>
              )}
            </Box>
            <Box width="100%">
              {countryLabel && (
                <FormLabel display="block" mb="3px">
                  {countryLabel}
                </FormLabel>
              )}

              <Controller
                control={control}
                name="country"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="lg"
                    variant={rtl ? "rtl" : "none"}
                    borderRadius={0}
                    {...field}
                    placeholder={countryLabel || ""}
                  >
                    {countries.length > 0 &&
                      countries.map((country, index) => {
                        return (
                          <option
                            key={`${country}-${index}`}
                            value={country.id}
                          >
                            {country.title}
                          </option>
                        );
                      })}
                  </Select>
                )}
              />
              {errors.country && errors.country.type === "required" && (
                <Text role="alert" fontSize="sm" color="red">
                  {errorCountry}
                </Text>
              )}
            </Box>
            <Box width="100%">
              <FormLabel display="block" mb="3px">
                {footerText.selectLanguage}
              </FormLabel>

              <Controller
                control={control}
                name="language"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="lg"
                    variant={rtl ? "rtl" : "none"}
                    borderRadius={0}
                    {...field}
                    placeholder={footerText.selectLanguage}
                  >
                    {listOfLanguages?.length > 0 &&
                      listOfLanguages?.map((lang, index) => {
                        return (
                          <option key={`${lang}-${index}`} value={lang.locale}>
                            {lang.title}
                          </option>
                        );
                      })}
                  </Select>
                )}
              />
              {errors.language && errors.language.type === "required" && (
                <Text role="alert" fontSize="sm" color="red">
                  {footerText.selectLanguage}
                </Text>
              )}
            </Box>
            <Box width="100%">
              {dateOfBirthText?.label && (
                <FormLabel display="block" mb="3px">
                  {dateOfBirthText.label}
                </FormLabel>
              )}

              <VStack spacing="0px">
                <Controller
                  control={control}
                  name="dateOfBirthDay"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      size="lg"
                      variant={rtl ? "rtl" : "none"}
                      borderRadius={0}
                      {...field}
                      placeholder={dateOfBirthText?.dayPlaceholder || ""}
                    >
                      <ReturnDays />
                    </Select>
                  )}
                />
                {errors.dateOfBirthDay &&
                  errors.dateOfBirthDay.type === "required" && (
                    <Text role="alert" fontSize="sm" color="red">
                      {errorDMY.errorDay}
                    </Text>
                  )}

                <Controller
                  control={control}
                  name="dateOfBirthMonth"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      size="lg"
                      variant={rtl ? "rtl" : "none"}
                      borderRadius={0}
                      {...field}
                      placeholder={dateOfBirthText?.monthPlaceholder || ""}
                    >
                      {monthsArr?.length > 0 &&
                        monthsArr.map((month) => (
                          <option
                            key={month.numeric}
                            value={parseInt(month.numeric).toLocaleString(
                              "en-US",
                              {
                                minimumIntegerDigits: 2,
                              }
                            )}
                          >
                            {month.long}
                          </option>
                        ))}
                    </Select>
                  )}
                />
                {errors.dateOfBirthMonth &&
                  errors.dateOfBirthMonth.type === "required" && (
                    <Text role="alert" fontSize="sm" color="red">
                      {errorDMY.errorMonth}
                    </Text>
                  )}

                <Controller
                  control={control}
                  name="dateOfBirthYear"
                  rules={{
                    required: true,
                    validate: (year) => validDateCheck(year),
                  }}
                  render={({ field }) => (
                    <Select
                      size="lg"
                      variant={rtl ? "rtl" : "none"}
                      borderRadius={0}
                      {...field}
                      placeholder={dateOfBirthText?.yearPlaceholder || ""}
                    >
                      <ReturnYears />
                    </Select>
                  )}
                />
                {errors.dateOfBirthYear &&
                  errors.dateOfBirthYear.type === "validate" && (
                    <Text role="alert" fontSize="sm" color="red">
                      {dmy[0].dmy?.errorMessageDOB}
                    </Text>
                  )}
                {errors.dateOfBirthYear &&
                  errors.dateOfBirthYear.type === "required" && (
                    <Text role="alert" fontSize="sm" color="red">
                      {errorDMY.errorYear}
                    </Text>
                  )}
              </VStack>
            </Box>
          </VStack>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            py={12}
            spacing="30px"
            maxWidth="400px"
            margin="0 auto"
          >
            <Button
              whiteSpace="break-spaces"
              variant="standardOutline"
              onClick={goBack}
            >
              {backButtonLabel || "Back"}
            </Button>
            <Button whiteSpace="break-spaces" variant="standard" type="submit">
              {saveButtonLabel || "Save"}
            </Button>
          </Stack>
        </form>
      ) : (
        <form onSubmit={handleSubmit(onSubmitVerify)}>
          <VStack
            spacing="20px"
            width="100%"
            maxWidth="400px"
            margin="0 auto"
            dir={dir}
          >
            <Text fontSize="sm" pt="15px">
              {infoVerificationCode}
            </Text>
            <Box width="100%">
              <Controller
                control={control}
                name="confirmationCodeField"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    size="sm"
                    variant="none"
                    borderRadius={0}
                    placeholder={textfieldVerifyCode.placeholder}
                  />
                )}
              />
              {errors.confirmationCodeField &&
                errors.confirmationCodeField.type === "required" && (
                  <Text role="alert" fontSize="sm" color="red">
                    {textfieldVerifyCode.errorMessage}
                  </Text>
                )}
            </Box>
          </VStack>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            py={12}
            spacing="30px"
            maxWidth="400px"
            margin="0 auto"
          >
            <Button
              whiteSpace="break-spaces"
              variant="standardOutline"
              onClick={goBack}
            >
              {backButtonLabel || "Back"}
            </Button>
            <Button whiteSpace="break-spaces" variant="standard" type="submit">
              {saveButtonLabel || "Save"}
            </Button>
          </Stack>
        </form>
      )}
    </>
  );
};

export default EditUserForm;
