import React, { createContext, useState, useEffect } from "react";

const config = {
  language: null,
  isAuthenticated: false,
  languages: [],
  setLanguage: () => {},
  setIsAuthenticated: () => {},
};

export const UserContext = createContext(config);

export const UserProvider = ({ children }) => {
  // supported languages
  const languages = [
    "bg",
    "cz",
    "de",
    "gr",
    "en",
    "es",
    "ee",
    "fi",
    "fr",
    "he",
    "hr",
    "hu",
    "it",
    "ge",
    "lt",
    "lv",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "al",
    "rs",
    "se",
    "tr",
    "ua",
  ];
  
  const listOfLanguages = [
    { locale: "bg", title: "български" },
    { locale: "cz", title: "Český" },
    { locale: "de", title: "Deutsch" },
    { locale: "gr", title: "Ελληνικά" },
    { locale: "en", title: "English" },
    { locale: "es", title: "Español" },
    { locale: "ee", title: "Eesti" },
    { locale: "fi", title: "Suomi" },
    { locale: "fr", title: "Français" },
    { locale: "he", title: "עברית" },
    { locale: "hr", title: "Hrvatski" },
    { locale: "hu", title: "Magyar" },
    { locale: "it", title: "Italiano" },
    { locale: "ge", title: "ქართული" },
    { locale: "lt", title: "Lietuvių" },
    { locale: "lv", title: "Latviešu" },
    { locale: "pl", title: "Polski" },
    { locale: "pt", title: "Português" },
    { locale: "ro", title: "Română" },
    { locale: "ru", title: "Русский" },
    { locale: "sk", title: "Slovenský" },
    { locale: "al", title: "Shqiptar" },
    { locale: "rs", title: "Српски" },
    { locale: "se", title: "Svenska" },
    { locale: "tr", title: "Türkçe" },
    { locale: "ua", title: "Українська" },
  ];

  const [language, setLanguage] = useState(() => {
    if (typeof window !== "undefined") {
      const localValue = JSON.parse(localStorage.getItem("language"));
      const activeLanguage =
        localValue && languages.includes(localValue) ? localValue : "";
      return activeLanguage;
    }
  });

  const storeLanguage = (lang) => {
    if (lang && typeof window !== "undefined") {
      localStorage.setItem("language", JSON.stringify(lang));
      setLanguage(lang);
    }
    return;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let local = localStorage.getItem("language");
      if (local && languages.includes(local)) {
        return;
      }
      storeLanguage("");
    }
    return;
  }, []);

  return (
    <UserContext.Provider
      value={{ storeLanguage, language, languages, listOfLanguages }}
    >
      {children}
    </UserContext.Provider>
  );
};
