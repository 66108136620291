import React from "react";
import { Image, Text, Box, useDisclosure } from "@chakra-ui/react";
import VideoModal from "./VideoModal";

const VideoCard = ({
  url,
  placeholderImage,
  name,
  description,
  title,
  dir,
  rtl,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const aligning = rtl ? "right" : "left";
  return (
    <>
      <Box maxW="100%" w="310px" p="10px">
        <Box cursor="pointer" pos="relative" data-url={url} onClick={onOpen}>
          <Image src={placeholderImage} alt={title} />
          <Image
            h="50px"
            src="play-btn.png"
            alt="play"
            pos="absolute"
            m="auto"
            top="-20px"
            left={0}
            right={0}
            bottom={0}
          />
          <Box
            pos="absolute"
            bottom={3}
            left={rtl ? "initial" : 3}
            right={rtl ? 3 : "initial"}
            maxW="100%"
            textAlign={aligning}
            lineHeight="8"
          >
            <Text
              fontFamily="Tracks Normal"
              fontWeight="normal"
              textTransform="uppercase"
              color="baseLight"
              fontSize="3xl"
              dir={dir}
            >
              {title}
            </Text>
          </Box>
        </Box>
        <Box width="100%" textAlign={aligning} p={2}>
          <Text color="primaryDarker" dir={dir}>
            {name}
          </Text>
          <Text color="baseLight" lineHeight="4" dir={dir}>
            {description}
          </Text>
        </Box>
      </Box>
      <VideoModal isOpen={isOpen} onClose={onClose} url={url} />
    </>
  );
};

export default VideoCard;
