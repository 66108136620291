import React from "react";
import NextLink from "next/link";
import { Flex, Text, VStack, Image, Box, Link } from "@chakra-ui/react";
import { ContentContainer } from ".";

const CourseCard = ({
  disabled,
  mb,
  title,
  completed,
  start,
  notAvailable,
  rtl,
  startCourse,
  course,
  status,
  icon,
  bgImage,
  downloadIcon,
}) => {
  const flexDirection = rtl ? "row-reverse" : "row";
  const dir = rtl ? "rtl" : "ltr";

  return (
    <Flex
      w="100vw"
      minH="250px"
      bg={status?.status == "PASSED" ? "secondary" : "primary"}
      borderTop="5px solid"
      borderBottom={mb ? "5px solid" : 0}
      borderColor="baseLight"
      opacity={disabled ? "50%" : "100%"}
      align="center"
      backgroundImage={
        status?.status == "PASSED"
          ? "none"
          : 'url("/modules/backgrounds/' + bgImage + '.jpg")'
      }
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      <ContentContainer>
        <Flex
          justify="space-between"
          align="center"
          width="100%"
          flexDirection={flexDirection}
        >
          <VStack
            spacing="15px"
            width={{ sm: "200px", md: "400px" }}
            justify={rtl ? "flex-end" : "flex-start"}
            align={rtl ? "flex-end" : "flex-start"}
            textAlign={rtl ? "right" : "left"}
          >
            <Text
              textTransform="uppercase"
              fontWeight="semibold"
              fontSize={{ xs: "3xl", md: "4xl" }}
              color="baseLight"
              dir={dir}
            >
              {title}
            </Text>
            <Box pos="relative" marginTop="15px" marginBottom="30px">
              <Box
                cursor="pointer"
                onClick={(event) => {
                  startCourse(course);
                  event.target.blur();
                }}
                pointerEvents={disabled ? "none" : "auto"}
              >
                <Link
                  variant={rtl ? "courseStart2" : "courseStart"}
                  fontSize="2xl"
                >
                  {disabled ? notAvailable : start}
                </Link>
              </Box>
              <Box
                display="block"
                pos="absolute"
                right={rtl ? "initial" : "36px"}
                left={rtl ? "36px" : "initial"}
                bottom={0}
                w="3000px"
                borderBottom="2px solid"
                borderColor="primaryDarker"
              />
            </Box>
          </VStack>
          <Box p={3}>
            <Image
              src={
                status?.status == "PASSED"
                  ? "modules/check-mark.svg"
                  : icon
                  ? "modules/icons/" + icon + ".png"
                  : "modules/thumbsup.png"
              }
              alt="Start module"
              objectFit="contain"
              h="100px"
            />
          </Box>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default CourseCard;
