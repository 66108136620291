import { extendTheme } from "@chakra-ui/react";
import { Button } from "./components/Button";
import { Input } from "./components/Input";
import { Select } from "./components/Select";
import { Checkbox } from "./components/Checkbox";
import { IconButton } from "./components/IconButton";
import { Link } from "./components/Link";
import { Text } from "./components/Text";
import { CloseButton } from "./components/CloseButton";
import { colors } from "./colors";
import { breakpoints } from "./breakpoints";
import Fonts from "./fonts";
import { styles } from "./styles";

const overrides = {
  breakpoints,
  styles,
  colors,
  Fonts,
  components: {
    Text,
    Link,
    Button,
    Input,
    Select,
    Checkbox,
    IconButton,
    CloseButton,
    // Other components go here
  },
};

const theme = extendTheme(overrides);

export default theme;
