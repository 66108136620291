export const Input = {
  // 1. We can update the base styles
  baseStyle: {
    field: {
      backgroundColor: "inputBackground",
      color: "baseDark",
      fontFamily: "Open Sans",
      fontWeight: "normal",
      borderBottomWidth: "2px",
      borderColor: "baseDark",
      rounded: 0,
      borderRadius: 0,
      _placeholder: {
        color: "baseDark",
      },
    },
    addon: {
      rounded: 0,
      borderRadius: 0,
    },
    //boxShadow: "0 0 2px 2px #efdfde",
  },
  // 2. We can add a new button size or extend existing
  sizes: {},
  // 3. We can add a new visual variant
  variants: {},
};
