import { createBreakpoints } from "@chakra-ui/theme-tools";

export const breakpoints = createBreakpoints({
  xs: "300px",
  sm: "500px", //mobile
  md: "615px", //tablet
  lg: "800px", //custom for course listing view
  xl: "930px", //desktop
  xxl: "1200px",
});
