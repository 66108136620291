import React from "react";
import NextLink from "next/link";
import { VStack, Image, Link, Text } from "@chakra-ui/react";

const Footer = ({ logo, caption, url, urlText, footerText, locale }) => {
  const currentYear = new Date().getFullYear();

  return (
    <VStack
      as="footer"
      spacing="30px"
      bg="primaryDarker"
      width="100vw"
      margin="0 auto"
      p={14}
      pb={28}
    >
      <Text color="footerText">
        {footerText?.copyrightText} © {currentYear}. {footerText?.rightsText}{" "}
        <Link
          _hover={{ color: "#eaeaea" }}
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {urlText}
        </Link>{" "}
        |{" "}
        <NextLink href="/home/privacy" locale={locale}>
          <Link _hover={{ color: "#eaeaea" }}>{footerText?.privacyText}</Link>
        </NextLink>{" "}
        |{" "}
        <NextLink href="/selectlanguage">
          <Link _hover={{ color: "#eaeaea" }}>
            {footerText?.selectLanguage
              ? footerText.selectLanguage
              : "Select language"}
          </Link>
        </NextLink>
      </Text>
      <Image h="80px" src={logo} alt={caption} />
    </VStack>
  );
};

export default Footer;
