/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState, forwardRef } from "react";
import {
  Box,
  Flex,
  Link as ChakraLink,
  Button,
  Image as ChakraImage,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { FiFile } from "react-icons/fi";
import Image from "next/image";
import Canvas from "src/components/Canvas";

const SoMeImage = (props) => {
  const {
    userImageUrl,
    departmentImageUrl,
    uploadImage,
    showUI = false,
  } = props;

  const [profileImageLoaded, setProfileImageLoaded] = useState(null);
  const [profileFrameLoaded, setProfileFrameLoaded] = useState(null);
  const [profileSportLoaded, setProfileSportLoaded] = useState(null);

  const canvasRef = useRef(null);
  const linkRef = useRef(null);
  const imgRef = useRef(null);
  const frameRef = useRef(null);
  const sportRef = useRef(null);
  const completeSoMeImageRef = useRef(null);

  const inputRef = useRef();

  const width = 1080;
  const height = 1080;
  const iconOffset = {
    x: 480,
    y: 725,
    scale: 0.11,
  };

  useEffect(() => {
    if (profileImageLoaded && profileFrameLoaded && profileSportLoaded) {
      const context = canvasRef.current.getContext("2d");
      draw(context);
    }
  }, [profileImageLoaded, profileFrameLoaded, profileSportLoaded]);

  const draw = (context) => {
    var img = imgRef.current; //new Image();
    let originWidth = img.naturalWidth;
    let originHeight = img.naturalHeight;
    let cropSize = originWidth > originHeight ? originHeight : originWidth;
    let cropOffsetX =
      originWidth > originHeight ? (originWidth - originHeight) / 2 : 0;
    let cropOffsetY =
      originWidth > originHeight ? 0 : (originHeight - originWidth) / 2;

    context.drawImage(
      img,
      cropOffsetX,
      cropOffsetY,
      cropSize,
      cropSize,
      0,
      0,
      width,
      height
    );

    // drawing the gradietn
    var gradient = context.createLinearGradient(0, 540, 0, 1080);
    gradient.addColorStop(0, "rgba(0, 47, 98, 0");
    gradient.addColorStop(0.5, "#002f62");
    context.fillStyle = gradient;
    context.fillRect(0, 540, 1080, 540);

    var frame = frameRef.current; //new Image();
    //frame.src = frameRef.current.src;
    context.drawImage(frame, 162, 756);

    var icon = sportRef.current; //new Image();
    //icon.src = sportRef.current.src;
    context.drawImage(icon, 918, 50, 100, 100);

    // adding the url
    context.fillStyle = "#fff";
    context.font = "42px Open Sans";
    context.textAlign = "center";
    context.fillText("www.irunclean.org", 540, 1036);

    prepDownloadImage();
  };

  function prepDownloadImage() {
    completeSoMeImageRef.current.src =
      canvasRef.current.toDataURL("image/jpeg");
    canvasRef?.current?.toBlob(
      function (blob) {
        linkRef.current.href = URL.createObjectURL(blob);
        //imgRef.current.src = URL.createObjectURL(blob);
      },
      "image/jpeg",
      0.95
    );
  }

  return (
    <Box display={showUI ? "block" : "none"}>
      {linkRef && <ChakraImage src={linkRef?.current?.href} display={"none"} />}
      <a
        ref={linkRef}
        href=""
        target="_blank"
        download="I_Run_Clean.jpg"
        style={{ textDecoration: "none" }}
      >
        <ChakraImage
          id="completeSoMeImage"
          ref={completeSoMeImageRef}
          alt="bildet"
          src=""
          crossOrigin="anonymous"
        />
        <Button
          variant="standard"
          aria-label="Download"
          leftIcon={<DownloadIcon />}
        >
          Download
        </Button>
      </a>
      <Box mb={"1rem"} mt={"0.5rem"}>
        <input
          type="file"
          accept="image/png, image/jpeg"
          name="imageUpload"
          onChange={(e) => {
            uploadImage(e, imgRef);
          }}
        />
      </Box>
      <Box mb={"1rem"} overflowX={"hidden"}>
        <div style={{ display: "none" }}>
          <Canvas ref={canvasRef} draw={draw} height={height} width={width} />
          <img
            ref={imgRef}
            alt=""
            src={userImageUrl}
            crossOrigin="anonymous"
            onLoad={() => {
              setProfileImageLoaded(true);
            }}
          />
          <img
            ref={frameRef}
            src={"/logo_ea_run_clean_white_shadow.png"}
            alt=""
            crossOrigin="anonymous"
            onLoad={() => {
              setProfileFrameLoaded(true);
            }}
          />
          <img
            ref={sportRef}
            src={departmentImageUrl || "/profile-some-banner.png"}
            alt=""
            crossOrigin="anonymous"
            onLoad={() => {
              setProfileSportLoaded(true);
            }}
          />
        </div>
      </Box>
    </Box>
  );
};
SoMeImage.displayName = "SoMeImage";
export default SoMeImage;
