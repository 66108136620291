import React from "react";
import { Box } from "@chakra-ui/react";

const ContentContainer = ({ pos, children }) => {
  return (
    <Box maxW="1000px" width="100%" pr={4} pl={4} margin="0 auto" pos={pos}>
      {children}
    </Box>
  );
};

export default ContentContainer;
