import "../../../aws-amplify.config";

import React, { useRef, useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import { UserContext } from "src/context/UserContext";

import {
  Alert,
  AlertIcon,
  Button,
  VStack,
  Stack,
  Text,
  Input,
  Box,
  FormLabel,
} from "@chakra-ui/react";

const ChangePasswordForm = ({
  goBack,
  currentPasswordPlaceholder,
  newPasswordPlaceholder,
  confirmNewPasswordPlaceholder,
  errorCurrent,
  errorNew,
  errorConfirm,
  changePasswordResponses,
  backButtonLabel,
  confirmButtonLabel,
  failedAttempts,
  dir,
}) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState();
  const newPasswordRef = useRef({});
  const router = useRouter();
  const { language } = useContext(UserContext);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  newPasswordRef.current = watch("newPassword", "");

  const navigate = (path) => {
    if (path) {
      router.push(`${path}`, `${path}`, { locale: language });
    }
  };

  const changePassword = async (data) => {
    if (data) {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            data.currentPassword,
            data.newPassword
          );
        })
        .then((data) => {
          console.log(data);
          setSuccessMsg(changePasswordResponses?.success);
          setTimeout(() => {
            navigate("/courses");
          }, 3000);
        })
        .catch((error) => {
          Sentry.captureMessage(
            `name:${error.name} | code:${error.code} | message:${error.message}`
          );
          console.log(error.code);
          if (error.code === "NotAuthorizedException") {
            setErrorMsg(changePasswordResponses?.wrongCurrentPassword);
          }
          if (error.code === "InvalidPasswordException") {
            setErrorMsg(changePasswordResponses?.passwordFormat);
          }
          if (error.code === "LimitExceededException") {
            setErrorMsg(failedAttempts);
          }
        });
    }
  };

  const onSubmit = (data) => {
    changePassword(data);
  };

  useEffect(() => {
    // when the data is saved, the alert is displayed for 3 seconds
    setTimeout(() => {
      setSuccessMsg();
    }, 3000);
  }, [successMsg]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack
        spacing="20px"
        width="100%"
        maxWidth="400px"
        margin="0 auto"
        dir={dir}
      >
        {successMsg && (
          <Alert status="success" mt="15px">
            <AlertIcon />
            <Box flex="1">{successMsg}</Box>
          </Alert>
        )}
        {errorMsg && !successMsg && (
          <Box pt="15px">
            <Text role="alert" fontSize="sm" color="red">
              {errorMsg}
            </Text>
          </Box>
        )}
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {currentPasswordPlaceholder}
          </FormLabel>
          <Controller
            control={control}
            name="currentPassword"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                autoComplete="current-password"
                variant="none"
                borderRadius={0}
                size="lg"
                placeholder={currentPasswordPlaceholder || ""}
              />
            )}
          />
          {errors.currentPassword &&
            errors.currentPassword.type === "required" && (
              <Text role="alert" fontSize="sm" color="red">
                {errorCurrent}
              </Text>
            )}
          {errors.currentPassword &&
            errors.currentPassword.type === "validate" && (
              <Text role="alert" fontSize="sm" color="red">
                {errors.currentPassword.message}
              </Text>
            )}
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {newPasswordPlaceholder}
          </FormLabel>
          <Controller
            control={control}
            name="newPassword"
            rules={{ required: true, minLength: 8 }}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                autoComplete="new-password"
                variant="none"
                borderRadius={0}
                size="lg"
                placeholder={newPasswordPlaceholder || ""}
              />
            )}
          />
          {errors.newPassword && errors.newPassword.type === "required" && (
            <Text role="alert" fontSize="sm" color="red">
              {errorNew}
            </Text>
          )}
          {errors.newPassword && errors.newPassword.type === "minLength" && (
            <Text role="alert" fontSize="sm" color="red">
              {changePasswordResponses?.passwordLength}
            </Text>
          )}
        </Box>
        <Box width="100%">
          <FormLabel display="block" mb="3px">
            {confirmNewPasswordPlaceholder}
          </FormLabel>
          <Controller
            control={control}
            name="confirmNewPassword"
            rules={{
              required: true,
              validate: (value) =>
                value === newPasswordRef.current ||
                changePasswordResponses?.passwordsNotMatching,
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                autoComplete="new-password"
                variant="none"
                borderRadius={0}
                size="lg"
                placeholder={confirmNewPasswordPlaceholder || ""}
              />
            )}
          />
          {errors.confirmNewPassword &&
            errors.confirmNewPassword.type === "required" && (
              <Text role="alert" fontSize="sm" color="red">
                {errorConfirm}
              </Text>
            )}
          {errors.confirmNewPassword && (
            <Text role="alert" fontSize="sm" color="red">
              {errors.confirmNewPassword.message}
            </Text>
          )}
        </Box>
      </VStack>
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        py={12}
        spacing="30px"
        maxWidth="400px"
        width="100%"
        margin="0 auto"
      >
        <Button
          whiteSpace="break-spaces"
          variant="standardOutline"
          onClick={goBack}
        >
          {backButtonLabel || "Cancel"}
        </Button>
        <Button whiteSpace="break-spaces" variant="standard" type="submit">
          {confirmButtonLabel || "Change password"}
        </Button>
      </Stack>
    </form>
  );
};

export default ChangePasswordForm;
