import React from "react";
import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useLMS } from "/src/hooks/api";
const CoursesProgressBar = ({
  courses,
  diplomaText,
  completedText,
  completeMore,
  user,
  learningpath,
  refresherpath,
  rtl,
}) => {
  const { forwardDiploma, downloadRefresherDiploma } = useLMS();

  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");

  const completedCourses = courses.filter(
    (course) => course.course_status.status === "PASSED"
  );

  const plainCompletedText = completedText
    .replace("{{number}}", completedCourses?.length)
    .replace("{{total}}", courses?.length);
  const plainCompleteMore = completeMore.replace(
    "{{more}}",
    courses?.length - completedCourses?.length
  );

  const dir = rtl ? "rtl" : "ltr";
  const flexDirection = rtl ? "row-reverse" : "row";

  const onDownloadDiploma = async (event) => {
    event.preventDefault();
    let response = await downloadRefresherDiploma({
      userId: user.id,
      learningpathId: learningpath.id,
      refresherLearningpathId: refresherpath.id,
    });
    // creating a hidden <a> tag
    var a = document.createElement("a");

    // creating a reference to the file
    var url = window.URL.createObjectURL(response.data);

    // setting anchor tag's href attribute to the blob's URL
    a.href = url;

    // setting anchor tag's download attribute to the filename
    a.download = "diploma.pdf";
    document.body.append(a);

    a.click();

    // after clicking it, remove it from the DOM
    a.remove();
    // release an existing object URL which was previously
    // created by calling URL.createObjectURL()
    // once we have finished using an object URL, let the
    // browser know not to keep the reference to the file any longer.
    window.URL.revokeObjectURL(url);
  };

  return (
    <Flex direction="column" justify="center" align="center">
      <HStack
        spacing="2px"
        bg="baseLight"
        borderBottomRightRadius="34px"
        borderTopLeftRadius="34px"
        overflow="hidden"
        flexDirection={flexDirection}
      >
        {isLargerThan900 && (
          <Flex bg="secondary" h="3em" align="center" justify="center">
            <Text
              color="baseLight"
              py={1}
              pl="50px"
              pr="20px"
              fontSize="lg"
              fontWeight="semibold"
            >
              {user?.name}
            </Text>
          </Flex>
        )}
        {courses.length > 0 &&
          courses.map((mod) => (
            <Box
              key={mod.id}
              bg={
                mod.course_status.status === "PASSED"
                  ? "secondary"
                  : "primaryMedium"
              }
              h="3em"
              w={{ xs: "2em", md: "3em" }}
            >
              {mod.course_status.status === "PASSED" && (
                <Image
                  src="/modules/check-mark.svg"
                  alt="check mark"
                  height={{ base: "20px", md: "23px" }}
                  width={{ base: "25px", md: "30px" }}
                  verticalAlign="middle"
                  mt="12px"
                  ml={{ base: "5px", md: "10px" }}
                />
              )}
            </Box>
          ))}
        {isLargerThan900 && (
          <Flex
            bg="primaryMedium"
            h="3em"
            align="center"
            justify="center"
            onClick={
              courses?.length === completedCourses?.length
                ? onDownloadDiploma
                : null
            }
            cursor={
              courses?.length === completedCourses?.length
                ? "pointer"
                : "default"
            }
          >
            <Text
              color="baseLight"
              py={1}
              pr="50px"
              pl="20px"
              fontSize="lg"
              fontWeight="semibold"
              opacity={courses?.length === completedCourses?.length ? 1 : 0.2}
            >
              {diplomaText}
            </Text>
          </Flex>
        )}
      </HStack>
      <Text
        pt={4}
        fontSize="lg"
        fontWeight="semibold"
        color="primaryDarker"
        dir={dir}
      >
        {plainCompletedText}{" "}
        {courses?.length - completedCourses?.length > 0 && plainCompleteMore}
      </Text>
    </Flex>
  );
};

export default CoursesProgressBar;
