export const Button = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: "semibold",
    //boxShadow: "0 0 2px 2px #efdfde",
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    xl: {
      h: "3em",
      fontSize: "lg",
    },
  },
  // 3. We can add a new visual variant
  variants: {
    standard: {
      bg: "primaryMedium",
      color: "baseLight",
      width: "100%",
      border: 0,
      rounded: 0,
      fontWeight: "semibold",
      _hover: {
        cursor: "pointer",
      },
    },
    standardOutline: {
      bg: "transparent",
      color: "primaryMedium",
      width: "100%",
      border: "1px solid",
      borderColor: "primaryMedium",
      rounded: 0,
      fontWeight: "semibold",
      _hover: {
        cursor: "pointer",
      },
    },
    delete: {
      bg: "delete",
      color: "baseLight",
      width: "100%",
      border: 0,
      rounded: 0,
      fontWeight: "semibold",
      _hover: {
        cursor: "pointer",
        _disabled: {
          bg: "delete",
          cursor: "default",
        },
      },
      _disabled: {
        opacity: "50%",
      },
    },
    active: {
      bg: "buttonActive",
      color: "baseLight",
      width: "100%",
      border: 0,
      rounded: 0,
      textTransform: "uppercase",
      fontWeight: "normal",
      _hover: {
        cursor: "default",
      },
    },
  },
};
