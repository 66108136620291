import "../styles/globals.css";
//import "@fontsource/open-sans/700.css";

import { ChakraProvider } from "@chakra-ui/react";
import { ApiProvider } from "src/hooks/api";
import { UserProvider } from "src/context/UserContext";
import { RouteGuard } from "src/components";
import { useRouter } from "next/router";
import theme from "src/theme";
import SiteLayout from "src/components/SiteLayout";
import { useEffect } from "react";

if (process.env.NODE_ENV === "development") {
  console.log("dev");
  //require("../mocks");
}

const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
const clientId = process.env.NEXT_PUBLIC_CLIENT_ID;

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <ApiProvider
      config={{
        apiBaseUrl,
        clientId,
      }}
    >
      <UserProvider>
        <ChakraProvider theme={theme}>
          <RouteGuard>
            <SiteLayout>
              <Component {...pageProps} />
            </SiteLayout>
          </RouteGuard>
        </ChakraProvider>
      </UserProvider>
    </ApiProvider>
  );
}

export default MyApp;
